import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/scheduled-requests.html APIdoc}
 *
 * `GET /API/scheduled-request/`
 *
 * @function scheduledrequest.listScheduledRequest
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listScheduledRequest({ ...props }) {
  const path = '/API/scheduled-request/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/scheduled-requests.html APIdoc}
 *
 * `GET /API/scheduled-request/${requestId}`
 *
 * @function scheduledrequest.getScheduledRequest
 * @param {Object} obj
 * @param {string} obj.requestId
 * @returns {Promise<Object>}
 */
function getScheduledRequest({ requestId = isRequired(), ...props }) {
  const path = `/API/scheduled-request/${requestId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/scheduled-requests.html APIdoc}
 *
 * `GET /API/scheduled-request/${requestId}/response`
 *
 * @function scheduledrequest.getScheduledRequestResponse
 * @param {Object} obj
 * @param {string} obj.requestId
 * @returns {Promise<Object>}
 */
function getScheduledRequestResponse({
  requestId = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/scheduled-request/${requestId}/response`;
  const headers = { accept: '*/*', ...propsHeaders };
  return vFetch({
    path,
    headers,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/scheduled-requests.html APIdoc}
 *
 * `DELETE /API/scheduled-request/${requestId}`
 *
 * @function scheduledrequest.removeScheduledRequest
 * @param {Object} obj
 * @param {string} obj.requestId
 * @returns {Promise<Object>}
 */
function removeScheduledRequest({ requestId = isRequired(), ...props }) {
  const path = `/API/scheduled-request/${requestId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/scheduled-requests.html APIdoc}
 *
 * `DELETE /API/scheduled-request`
 *
 * @function scheduledrequest.removeAllScheduledRequest
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeAllScheduledRequest({ ...props }) {
  const path = '/API/scheduled-request';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listScheduledRequest,
  getScheduledRequest,
  getScheduledRequestResponse,
  removeScheduledRequest,
  removeAllScheduledRequest,
};
