import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/migration.html APIdoc}
 *
 * `GET /API/metadata/migration`
 *
 * @function metadatamigration.listMetadataMigration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listMetadataMigration({ ...props } = {}) {
  const path = '/API/metadata/migration';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/migration.html APIdoc}
 *
 * `GET /API/metadata/migration/${migrationId}`
 *
 * @function metadatamigration.getMetadataMigration
 * @param {Object} obj
 * @param {string} obj.migrationId
 * @returns {Promise<Object>}
 */
function getMetadataMigration({ migrationId = isRequired(), ...props }) {
  const path = `/API/metadata/migration/${migrationId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/migration.html APIdoc}
 *
 * `POST /API/metadata/migration`
 *
 * @function metadatamigration.createMetadataMigration
 * @param {Object} obj
 * @param {Object} obj.metadataSchemaMigrationDocument
 * @returns {Promise<Object>}
 */
function createMetadataMigration({ metadataSchemaMigrationDocument = isRequired(), ...props }) {
  const path = '/API/metadata/migration';
  return vFetch({
    path,
    method: 'POST',
    body: metadataSchemaMigrationDocument,
    ...props,
  });
}

export default {
  listMetadataMigration,
  getMetadataMigration,
  createMetadataMigration,
};
