import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/self-test.html APIdoc}
 *
 * `GET /API/selftest/`
 *
 * @function selftest.listSelfTest
 * @param {Object} obj
 * @param {boolean} obj.noAuth
 * @returns {Promise<Object>}
 */
function listSelfTest({ noAuth = false, ...props } = {}) {
  const path = noAuth ? '/APInoauth/selftest/' : '/API/selftest/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/self-test.html APIdoc}
 *
 * `GET /API/selftest/${testName}`
 *
 * @function selftest.getSelfTest
 * @param {Object} obj
 * @param {string} obj.testName
 * @returns {Promise<Object>}
 */
function getSelfTest({ testName = isRequired(), ...props } = {}) {
  const path = `/API/selftest/${testName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  listSelfTest,
  getSelfTest,
};
