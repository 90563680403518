import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/whoami`
 *
 * @function user.whoAmI
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function whoAmI({ headers: propsHeaders = {}, ...props } = {}) {
  const path = '/API/whoami';
  const headers = { accept: 'text/plain', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${username}/token`
 *
 * @function user.getUserToken
 * @param {Object} obj
 * @param {string} obj.username
 * @param {string} obj.password
 * @returns {Promise<Object>}
 */
function getUserToken({ username = isRequired(), password, headers: propHeaders = {}, ...props }) {
  const headers = {
    username,
    password,
    accept: 'text/plain',
    ...propHeaders,
  };
  const path = `/API/user/${username}/token`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/`
 *
 * @function user.listUser
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listUser({ ...props } = {}) {
  const path = '/API/user/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `POST /API/user/`
 *
 * @function user.createUser
 * @param {Object} obj
 * @param {Object} obj.userDocument
 * @returns {Promise<Object>}
 */
function createUser({ userDocument, ...props }) {
  const path = '/API/user/';
  return vFetch({
    path,
    method: 'POST',
    body: userDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}`
 *
 * @function user.getUser
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getUser({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/graph`
 *
 * @function user.getUserGraph
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getUserGraph({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/graph`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/${userName}`
 *
 * @function user.updateUser
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {Object} obj.userDocument
 * @returns {Promise<Object>}
 */
function updateUser({ userName = isRequired(), userDocument = isRequired(), ...props }) {
  const path = `/API/user/${userName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: userDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `DELETE /API/user/${userName}`
 *
 * @function user.disableUser
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function disableUser({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/${userName}/enable`
 *
 * @function user.enableUser
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function enableUser({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/enable`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/`
 *
 * @function user.searchUser
 * @param {Object} obj
 * @param {Object} obj.userSearchDocument
 * @returns {Promise<Object>}
 */
function searchUser({ userSearchDocument, ...props }) {
  const path = '/API/user/';
  return vFetch({
    path,
    method: 'PUT',
    body: userSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/${userName}/alias/${alias}`
 *
 * @function user.createAlias
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.alias
 * @returns {Promise<Object>}
 */
function createAlias({ userName = isRequired(), alias = isRequired(), ...props }) {
  const path = `/API/user/${userName}/alias/${alias}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `DELETE /API/user/${userName}/alias/${alias}`
 *
 * @function user.removeAlias
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.alias
 * @returns {Promise<Object>}
 */
function removeAlias({ userName = isRequired(), alias = isRequired(), ...props }) {
  const path = `/API/user/${userName}/alias/${alias}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/realname`
 *
 * @function user.getUserRealName
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getUserRealName({ userName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'text/plain', ...propHeaders };
  const path = `/API/user/${userName}/realname`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/${userName}/realname`
 *
 * @function user.updateUserRealName
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.realName
 * @returns {Promise<Object>}
 */
function updateUserRealName({
  userName = isRequired(),
  realName,
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', contentType: 'text/plain', ...propHeaders };
  const path = `/API/user/${userName}/realname`;
  return vFetch({
    path,
    method: 'PUT',
    body: realName,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/${userName}/password/`
 *
 * @function user.updateUserPassword
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.password
 * @returns {Promise<Object>}
 */
function updateUserPassword({
  userName = isRequired(),
  password = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', contentType: 'text/plain', ...propHeaders };
  const path = `/API/user/${userName}/password/`;
  return vFetch({
    path,
    method: 'PUT',
    body: password,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/validate/`
 *
 * @function user.validateUserPassword
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.password
 * @returns {Promise<Object>}
 */
function validateUserPassword({
  userName = isRequired(),
  password = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const headers = { accept: 'text/plain', contentType: 'text/plain', ...propHeaders };
  const path = `/API/user/${userName}/validate/`;
  return vFetch({
    path,
    method: 'PUT',
    body: password,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/password/salt`
 *
 * @function user.getUserSalt
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getUserSalt({ userName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'application/octet-stream', ...propHeaders };
  const path = `/API/user/${userName}/password/salt`;
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `POST /API/user/${userName}/password/salt`
 *
 * @function user.createUserSalt
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function createUserSalt({ userName = isRequired(), headers: propHeaders = {}, ...props }) {
  const headers = { accept: 'application/octet-stream', ...propHeaders };
  const path = `/API/user/${userName}/password/salt`;
  return vFetch({
    path,
    method: 'POST',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/groups/`
 *
 * @function user.getUserGroup
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getUserGroup({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/groups/`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/roles`
 *
 * @function user.getUserRole
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getUserRole({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/roles`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/allgroups`
 *
 * @function user.getUserGroupRole
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getUserGroupRole({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/allgroups`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/${userName}/groups/`
 *
 * @function user.updateUserGroup
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {Object} obj.groupListDocument
 * @returns {Promise<Object>}
 */
function updateUserGroup({ userName = isRequired(), groupListDocument, ...props }) {
  const path = `/API/user/${userName}/groups/`;
  return vFetch({
    path,
    method: 'PUT',
    body: groupListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/token`
 *
 * @function user.getToken
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getToken({ headers: propsHeaders = {}, ...props } = {}) {
  const path = '/API/token';
  const headers = { accept: 'text/plain', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/key`
 *
 * @function user.listKey
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function listKey({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/key`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `GET /API/user/${userName}/key/${keyId}`
 *
 * @function user.getKey
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.keyId
 * @returns {Promise<Object>}
 */
function getKey({ userName = isRequired(), keyId = isRequired(), ...props }) {
  const path = `/API/user/${userName}/key/${keyId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `POST /API/user/${userName}/key/`
 *
 * @function user.createKey
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function createKey({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/key/`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `PUT /API/user/${userName}/key/${keyId}`
 *
 * @function user.updateKey
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.keyId
 * @param {Object} obj.accessKeyDocument
 * @returns {Promise<Object>}
 */
function updateKey({ userName = isRequired(), keyId = isRequired(), accessKeyDocument, ...props }) {
  const path = `/API/user/${userName}/key/${keyId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: accessKeyDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user/index.html APIdoc}
 *
 * `DELETE /API/user/${userName}/key/${keyId}`
 *
 * @function user.removeKey
 * @param {Object} obj
 * @param {string} obj.userName
 * @param {string} obj.keyId
 * @returns {Promise<Object>}
 */
function removeKey({ userName = isRequired(), keyId = isRequired(), ...props }) {
  const path = `/API/user/${userName}/key/${keyId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/user.html#see-access-control-entries-for-user APIdoc}
 *
 * `GET /API/user/${userName}/access`
 *
 * @function user.getAccess
 * @param {Object} obj
 * @param {string} obj.userName
 * @returns {Promise<Object>}
 */
function getAccess({ userName = isRequired(), ...props }) {
  const path = `/API/user/${userName}/access`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  whoAmI,
  getUserToken,
  listUser,
  createUser,
  getUser,
  getUserGraph,
  updateUser,
  disableUser,
  enableUser,
  searchUser,
  createAlias,
  removeAlias,
  getUserRealName,
  updateUserRealName,
  updateUserPassword,
  validateUserPassword,
  getUserSalt,
  createUserSalt,
  getUserGroup,
  getUserRole,
  getUserGroupRole,
  updateUserGroup,
  getToken,
  listKey,
  getKey,
  createKey,
  updateKey,
  removeKey,
  getAccess,
};
