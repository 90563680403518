export const PAL = 'PAL';
export const NTSC = 'NTSC';
export const NTSC30 = 'NTSC30';

export const CONSTANT_TIMEBASES = {
  [PAL]: { denominator: 25, numerator: 1 },
  [NTSC]: { denominator: 30000, numerator: 1001 },
  [NTSC30]: { denominator: 30, numerator: 1 },
};

export const FRAME_SEPARATORS = {
  ':': { dropFrame: false, field: 2 },
  ';': { dropFrame: true, field: 2 },
  '.': { dropFrame: false, field: 1 },
  ',': { dropFrame: true, field: 1 },
};
