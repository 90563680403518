import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import-settings.html APIdoc}
 *
 * `GET /API/import/settings`
 *
 * @function importsettings.listImportSettings
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listImportSettings({ ...props } = {}) {
  const path = '/API/import/settings';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import-settings.html APIdoc}
 *
 * `POST /API/import/settings`
 *
 * @function importsettings.createImportSettings
 * @param {Object} obj
 * @param {Object} obj.importSettingsDocument
 * @returns {Promise<Object>}
 */
function createImportSettings({ importSettingsDocument, ...props }) {
  const path = '/API/import/settings';
  return vFetch({
    path,
    method: 'POST',
    body: importSettingsDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import-settings.html APIdoc}
 *
 * `GET /API/import/settings/${settingsId}`
 *
 * @function importsettings.getImportSettings
 * @param {Object} obj
 * @param {string} obj.settingsId
 * @returns {Promise<Object>}
 */
function getImportSettings({ settingsId = isRequired(), ...props }) {
  const path = `/API/import/settings/${settingsId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import-settings.html APIdoc}
 *
 * `PUT /API/import/settings/${settingsId}`
 *
 * @function importsettings.updateImportSettings
 * @param {Object} obj
 * @param {string} obj.settingsId
 * @param {Object} obj.importSettingsDocument
 * @returns {Promise<Object>}
 */
function updateImportSettings({ settingsId = isRequired(), importSettingsDocument, ...props }) {
  const path = `/API/import/settings/${settingsId}`;
  return vFetch({
    path,
    method: 'PUT',
    body: importSettingsDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/import-settings.html APIdoc}
 *
 * `DELETE /API/import/settings/${settingsId}`
 *
 * @function importsettings.removeImportSettings
 * @param {Object} obj
 * @param {string} obj.settingsId
 * @returns {Promise<Object>}
 */
function removeImportSettings({ settingsId = isRequired(), ...props }) {
  const path = `/API/import/settings/${settingsId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listImportSettings,
  createImportSettings,
  getImportSettings,
  updateImportSettings,
  removeImportSettings,
};
