import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/conform.html#start-a-conform-job APIdoc}
 *
 * `POST /API/conform`
 *
 * @function conform.createConform
 * @param {Object} obj
 * @param {Object} obj.conformRequestDocument
 * @returns {Promise<Object>}
 */
function createConform({ conformRequestDocument = isRequired(), ...props }) {
  const path = '/API/conform';
  return vFetch({
    path,
    body: conformRequestDocument,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/conform.html#start-a-conform-job-for-an-existing-item APIdoc}
 *
 * `POST /API/item/${itemId}/timeline/${timelineFormat}/conform`
 *
 * @function conform.createConformItem
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.timelineFormat
 * @param {Object} obj.conformRequestDocument
 * @returns {Promise<Object>}
 */
function createConformItem({
  itemId = isRequired(),
  timelineFormat = isRequired(),
  conformRequestDocument = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/timeline/${timelineFormat}/conform`;
  return vFetch({
    path,
    body: conformRequestDocument,
    method: 'POST',
    ...props,
  });
}

export default {
  createConform,
  createConformItem,
};
