import parseFileSize from 'filesize';
import { formatTimeBaseText, formatTimeCodeType } from './time';
import parseKeyValuePairType from './parseKeyValuePairType';

const gcd = (a, b) => (b ? gcd(b, a % b) : a);

const parseAspectRatio = ({ width, height }) => {
  const denominator = gcd(width, height);
  let widthRatio = width / denominator;
  let heightRatio = height / denominator;
  if (widthRatio > 21) {
    heightRatio = 1;
    widthRatio = (width / height).toFixed(2);
  }
  return [widthRatio, heightRatio].join(':');
};

const parseBaseMediaInfoType = (baseMediaInfoType = {}) => {
  const { property: keyValuePairType, ...props } = baseMediaInfoType;
  const parsedKeyValuePairType = parseKeyValuePairType(keyValuePairType);
  return { ...parsedKeyValuePairType, ...props };
};

const parseFileType = (fileType) => {
  const { uri: uriList = [], size: fileSizeBytes, hash, path } = fileType;
  const [uri] = uriList;
  const fileSize = fileSizeBytes !== undefined ? parseFileSize(fileSizeBytes) : '';
  const fileName = path ? path.split('/').pop() : undefined;
  return {
    uri,
    hash,
    fileSize,
    fileSizeBytes,
    path,
    fileName,
  };
};

const parseContainerComponent = (containerComponent) => {
  const {
    mediaInfo = {},
    metadata = [],
    file: fileList,
    duration: durationTimeCode,
  } = containerComponent;
  const containerMetadata = parseKeyValuePairType(metadata);
  const containerMediaInfo = parseBaseMediaInfoType(mediaInfo);
  let { Format: containerFormat } = containerMediaInfo;
  const {
    Video_Format_List: videoFormat,
    Audio_Format_List: audioFormat,
    Text_Format_List: textFormat,
  } = containerMediaInfo;
  if (containerFormat === undefined) {
    const { format } = containerComponent;
    containerFormat = format;
    if (format && format.includes(',')) {
      const formatList = format.split(',');
      [containerFormat] = formatList;
    }
  }
  let parsedContainerComponent = {
    containerFormat,
    videoFormat,
    audioFormat,
    textFormat,
    containerMetadata,
    containerMediaInfo,
  };
  if (containerMetadata.componentOriginalFilename) {
    parsedContainerComponent.originalFilename = containerMetadata.componentOriginalFilename;
  }
  if (durationTimeCode !== undefined && durationTimeCode.samples !== 0) {
    parsedContainerComponent.duration = formatTimeCodeType(durationTimeCode).toDuration();
  }
  if (fileList) {
    const [fileType] = fileList;
    const parsedFileType = parseFileType(fileType);
    parsedContainerComponent = {
      ...parsedContainerComponent,
      ...parsedFileType,
    };
  }
  return parsedContainerComponent;
};

const parseVideoComponent = (videoComponent) => {
  const {
    codec: videoCodec,
    bitrate,
    fieldOrder,
    resolution = {},
    mediaInfo = {},
    metadata = [],
  } = videoComponent;
  const videoMediaInfo = parseBaseMediaInfoType(mediaInfo);
  const videoMetadata = parseKeyValuePairType(metadata);
  const {
    Format: videoFormat,
    'Frame rate': timeBaseText,
    'Color space': colorSpace,
    'Color primaries': colorPrimaries,
    Colorimetry: chromaSubsampling,
  } = videoMediaInfo;
  let timeBase;
  const { averageFrameRate, realBaseFrameRate } = videoComponent;
  if (realBaseFrameRate !== undefined) {
    timeBase = {
      numerator: realBaseFrameRate.denominator,
      denominator: realBaseFrameRate.numerator,
    };
  } else if (timeBaseText !== undefined) {
    timeBase = formatTimeBaseText(timeBaseText);
  } else if (averageFrameRate !== undefined) {
    timeBase = {
      numerator: averageFrameRate.denominator,
      denominator: averageFrameRate.numerator,
    };
  }
  let { 'Frame count': samples } = videoMediaInfo;
  if (samples === undefined) {
    const { numberOfPackets } = videoComponent;
    if (numberOfPackets !== undefined) samples = numberOfPackets;
  }
  let timeCode;
  let frameRate;
  let smpte;
  if (samples && timeBase) {
    timeCode = formatTimeCodeType({ samples, timeBase });
    frameRate = timeCode.timeBase.toRate(true);
    smpte = timeCode.toSmpte(true);
  }
  const { height, width } = resolution;
  const aspectRatio = parseAspectRatio(resolution);
  const dimension = `${width}x${height}`;
  const videoBitrate = bitrate ? parseFileSize(bitrate, { bits: true }) : undefined;
  return {
    videoFormat,
    dimension,
    frameRate,
    height,
    width,
    timeBase,
    timeCode,
    videoCodec,
    smpte,
    videoBitrate,
    fieldOrder,
    colorSpace,
    chromaSubsampling,
    aspectRatio,
    colorPrimaries,
    videoMetadata,
    videoMediaInfo,
  };
};

const parseAudioComponent = (audioComponent) => {
  const {
    timeBase = {},
    channelCount: audioChannels,
    codec: audioCodec,
    mediaInfo = {},
    bitrate,
    metadata = [],
  } = audioComponent;
  const audioMediaInfo = parseBaseMediaInfoType(mediaInfo);
  const audioMetadata = parseKeyValuePairType(metadata);
  const {
    Format: audioFormat,
    Resolution: audioBitDepth,
    Bit_rate_mode: audioBitRateMode,
  } = audioMediaInfo;
  const audioSamplerateSamples = timeBase.denominator
    ? timeBase.denominator / timeBase.numerator
    : undefined;
  const audioSamplerate = audioSamplerateSamples
    ? parseFileSize(audioSamplerateSamples, {
        base: 10,
        round: 1,
        symbols: { kB: 'kHz', B: 'Hz', mB: 'mHz' },
      })
    : undefined;
  const audioBitrate = bitrate
    ? parseFileSize(bitrate, { base: 10, round: 2, symbols: { kB: 'kbps', B: 'bps', MB: 'mbps' } })
    : undefined;
  return {
    audioFormat,
    audioCodec,
    audioSamplerate,
    audioSamplerateSamples,
    audioBitDepth,
    audioBitrate,
    audioBitRateMode,
    audioChannels,
    audioMediaInfo,
    audioMetadata,
  };
};

const parseBinaryComponent = (binaryComponent) => {
  const { file: fileList, mediaInfo = {}, metadata = [] } = binaryComponent;
  const binaryMetadata = parseKeyValuePairType(metadata);
  const binaryMediaInfo = parseBaseMediaInfoType(mediaInfo);

  let parsedBinaryComponent = { binaryMetadata, binaryMediaInfo };
  if (fileList) {
    const [fileType] = fileList;
    const parsedFileType = parseFileType(fileType);
    parsedBinaryComponent = {
      ...parsedBinaryComponent,
      ...parsedFileType,
    };
  }
  return parsedBinaryComponent;
};

const parseShapeType = (shapeType = {}) => {
  let parsedShape = {};
  const {
    containerComponent,
    videoComponent: videoComponentList,
    audioComponent: audioComponentList,
    binaryComponent: binaryComponentList,
    mimeType: mimeTypeList = [],
    tag: tagList = [],
  } = shapeType;
  [parsedShape.tag] = tagList;
  [parsedShape.mimeType] = mimeTypeList;
  if (containerComponent) {
    const parsedContainerComponent = parseContainerComponent(containerComponent, shapeType);
    parsedShape = {
      ...parsedShape,
      ...parsedContainerComponent,
    };
  }
  if (videoComponentList) {
    const [videoComponent] = videoComponentList;
    const parsedVideoComponent = parseVideoComponent(videoComponent, shapeType);
    parsedShape = {
      ...parsedShape,
      ...parsedVideoComponent,
    };
  }
  if (audioComponentList) {
    const [audioComponent] = audioComponentList;
    const parsedAudioComponent = parseAudioComponent(audioComponent, shapeType);
    parsedShape = {
      ...parsedShape,
      ...parsedAudioComponent,
    };
  }
  if (binaryComponentList) {
    const [binaryComponent] = binaryComponentList;
    const parsedBinaryComponent = parseBinaryComponent(binaryComponent, shapeType);
    parsedShape = {
      ...parsedShape,
      ...parsedBinaryComponent,
    };
  }
  return parsedShape;
};

export {
  parseAspectRatio,
  parseBaseMediaInfoType,
  parseFileType,
  parseContainerComponent,
  parseVideoComponent,
  parseAudioComponent,
  parseBinaryComponent,
  parseShapeType,
};
