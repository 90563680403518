import React from 'react';

export default function useThumbnailScrub(srcList = []) {
  const savedInterval = React.useRef();
  const initialImgIdx = React.useMemo(() => Math.floor(srcList.length / 2), [srcList]);
  const [imgIdx, setImgIdx] = React.useState();
  const imgIdxRef = React.useRef(imgIdx);
  React.useEffect(() => () => savedInterval.current && clearInterval(savedInterval.current), []);
  if (srcList.length === 0) return { width: 0 };

  imgIdxRef.current = imgIdx;
  const onMouseEnter = () => {
    const delay = 500;
    const tick = () => {
      if (imgIdxRef.current === undefined) {
        setImgIdx(0);
      } else if (imgIdxRef.current === srcList.length - 1) {
        clearInterval(savedInterval.current);
      } else {
        setImgIdx(imgIdxRef.current + 1);
      }
    };
    savedInterval.current = setInterval(tick, delay);
  };
  const onMouseLeave = () => {
    setImgIdx();
    clearInterval(savedInterval.current);
  };
  const imageSrc = srcList[imgIdx === undefined ? initialImgIdx : imgIdx];
  const width = imgIdx === undefined ? 0 : `${Math.floor((imgIdx / (srcList.length - 1)) * 100)}%`;
  return {
    imageSrc,
    onMouseEnter,
    onMouseLeave,
    width,
  };
}
