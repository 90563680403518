import { item as ItemApi } from '@vidispine/vdt-api';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useApi from './useApi';

export default function useSearchItem({ itemSearchDocument, queryParams, matrixParams }) {
  const { request, data: itemListType = {}, isLoading, isError } = useApi(ItemApi.searchItem);
  const onRefresh = () => request({ itemSearchDocument, queryParams, matrixParams });
  useDeepCompareEffect(() => {
    onRefresh();
  }, [itemSearchDocument, queryParams, matrixParams]);
  return {
    itemListType,
    isLoading,
    isError,
    onRefresh,
  };
}
