import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/dataset-ref.html APIdoc}
 *
 * `GET /API/metadata/dataset`
 *
 * @function metadatadataset.listMetadataDataset
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listMetadataDataset({ ...props } = {}) {
  const path = '/API/metadata/dataset';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/dataset-ref.html APIdoc}
 *
 * `GET /API/metadata/dataset/${datasetId}`
 *
 * @function metadatadataset.getMetadataDataset
 * @param {Object} obj
 * @param {string} obj.datasetId
 * @returns {Promise<Object>}
 */
function getMetadataDataset({ datasetId = isRequired(), ...props }) {
  const path = `/API/metadata/dataset/${datasetId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/dataset-ref.html APIdoc}
 *
 * `PUT /API/metadata/dataset/${datasetId}`
 *
 * @function metadatadataset.updateMetadataMigration
 * @param {Object} obj
 * @param {string} obj.datasetId
 * @param {Object} obj.body
 * @returns {Promise<Object>}
 */
function updateMetadataMigration({ datasetId = isRequired(), body = isRequired(), ...props }) {
  const path = `/API/metadata/dataset/${datasetId}`;
  return vFetch({
    path,
    method: 'PUT',
    body,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/dataset-ref.html APIdoc}
 *
 * `DELETE /API/metadata/dataset/${datasetId}`
 *
 * @function metadatadataset.removeMetadataDataset
 * @param {Object} obj
 * @param {string} obj.datasetId
 * @returns {Promise<Object>}
 */
function removeMetadataDataset({ datasetId = isRequired(), ...props }) {
  const path = `/API/metadata/dataset/${datasetId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listMetadataDataset,
  getMetadataDataset,
  updateMetadataMigration,
  removeMetadataDataset,
};
