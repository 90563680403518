import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `PUT /API/item/${itemId}/sequence/${format}`
 *
 * @function sequence.updateItemSequence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.format
 * @param {Object} obj.sequenceDefinition
 * @returns {Promise<Object>}
 */
function updateItemSequence({
  itemId = isRequired(),
  format = isRequired(),
  sequenceDefinition,
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/sequence/${format}`;
  const headers = { contentType: 'application/octet-stream', ...propsHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body: sequenceDefinition,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `GET /API/item/${itemId}/sequence`
 *
 * @function sequence.listItemSequence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function listItemSequence({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/sequence`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `GET /API/item/${itemId}/sequence/${format}`
 *
 * @function sequence.getItemSequence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function getItemSequence({
  itemId = isRequired(),
  format = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/item/${itemId}/sequence/${format}`;
  const headers = { accept: '*/*', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `POST /API/item/${itemId}/sequence/conform-metadata`
 *
 * @function sequence.conformItemSequence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function conformItemSequence({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/sequence/conform-metadata`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `POST /API/item/${itemId}/sequence/render`
 *
 * @function sequence.renderItemSequence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @returns {Promise<Object>}
 */
function renderItemSequence({ itemId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/sequence/render`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/sequence/${format}`
 *
 * @function sequence.removeItemSequence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function removeItemSequence({ itemId = isRequired(), format = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/sequence/${format}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `POST /API/sequence/render`
 *
 * @function sequence.renderSequence
 * @param {Object} obj
 * @param {Object} obj.sequenceRenderRequestDocument
 * @returns {Promise<Object>}
 */
function renderSequence({ sequenceRenderRequestDocument = isRequired(), ...props }) {
  const path = '/API/sequence/render';
  return vFetch({
    path,
    method: 'POST',
    body: sequenceRenderRequestDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `POST /API/sequence/export`
 *
 * @function sequence.exportSequence
 * @param {Object} obj
 * @param {Object} obj.exportRequestDocument
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function exportSequence({ queryParams = isRequired(), exportRequestDocument, ...props }) {
  const path = '/API/sequence/export';
  return vFetch({
    path,
    method: 'POST',
    body: exportRequestDocument,
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/sequence.html APIdoc}
 *
 * `GET/POST /API/item/${itemId}/sequence/export`
 *
 * @function sequence.exportItemSequence
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {Object} obj.queryParams
 * @param {Object} obj.exportRequestDocument
 * @returns {Promise<Object>}
 */
function exportItemSequence({
  itemId = isRequired(),
  queryParams = isRequired(),
  exportRequestDocument,
  ...props
}) {
  const path = `/API/item/${itemId}/sequence/export`;
  let method = 'GET';
  if (exportRequestDocument) {
    method = 'POST';
  }
  return vFetch({
    path,
    method,
    body: exportRequestDocument,
    queryParams,
    ...props,
  });
}

export default {
  updateItemSequence,
  listItemSequence,
  getItemSequence,
  conformItemSequence,
  renderItemSequence,
  removeItemSequence,
  renderSequence,
  exportSequence,
  exportItemSequence,
};
