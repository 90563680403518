import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `GET /API/export-template`
 *
 * @function exporttemplate.listExportTemplate
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listExportTemplete({ ...props } = {}) {
  return vFetch({
    path: '/API/export-template',
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `GET /API/export-template/${templateName}`
 *
 * @function exporttemplate.getExportTemplate
 * @param {Object} obj
 * @param {string} obj.templateName
 * @returns {Promise<Object>}
 */
function getExportTemplate({ templateName = isRequired(), ...props }) {
  return vFetch({
    path: `/API/export-template/${templateName}`,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `DELTE /API/export-template/${templateName}`
 *
 * @function exporttemplate.getExportTemplate
 * @param {Object} obj
 * @param {string} obj.templateName
 * @returns {Promise<Object>}
 */
function removeExportTemplate({ templateName = isRequired(), ...props }) {
  return vFetch({
    path: `/API/export-template/${templateName}`,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `PUT /API/export-template/${templateName}`
 *
 * @function exporttemplate.createExportTemplate
 * @param {Object} obj
 * @param {string} obj.templateName
 * @param {string} obj.exportTemplateDocument
 * @returns {Promise<Object>}
 */
function createExportTemplate({ templateName = isRequired(), exportTemplateDocument, ...props }) {
  return vFetch({
    path: `/API/export-template/${templateName}`,
    method: 'PUT',
    body: exportTemplateDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `PUT /API/export-template/${templateName}`
 *
 * @function exporttemplate.updateExportTemplate
 * @param {Object} obj
 * @param {string} obj.templateName
 * @param {string} obj.exportTemplateDocument
 * @returns {Promise<Object>}
 */
function updateExportTemplate({ templateName = isRequired(), exportTemplateDocument, ...props }) {
  return vFetch({
    path: `/API/export-template/${templateName}`,
    method: 'PUT',
    body: exportTemplateDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `GET /API/export-template/${templateName}/graph`
 *
 * @function exporttemplate.getExportTemplateGraph
 * @param {Object} obj
 * @param {string} obj.templateName
 * @returns {Promise<Object>}
 */
function getExportTemplateGraph({
  templateName = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/export-template/${templateName}/graph`;
  const headers = { accept: 'image/png', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `GET /API/export-template/${templateName}/graph/dot`
 *
 * @function exporttemplate.getExportTemplateGraphDot
 * @param {Object} obj
 * @param {string} obj.templateName
 * @returns {Promise<Object>}
 */
function getExportTemplateGraphDot({
  templateName = isRequired(),
  headers: propHeaders = {},
  ...props
}) {
  const path = `/API/export-template/${templateName}/graph/dot`;
  const headers = { accept: 'text/plain', ...propHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `POST /API/export-template/test`
 *
 * @function exporttemplate.createExportTemplateTest
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function createExportTemplateTest({ ...props }) {
  const path = '/API/export-template/test';
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/item/export-templates.html APIdoc}
 *
 * `PUT /API/export-template/test`
 *
 * @function exporttemplate.createExportTemplateTestRender
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function createExportTemplateTestRender({ ...props }) {
  const path = '/API/export-template/test';
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

export default {
  listExportTemplete,
  getExportTemplate,
  removeExportTemplate,
  createExportTemplate,
  updateExportTemplate,
  getExportTemplateGraph,
  getExportTemplateGraphDot,
  createExportTemplateTest,
  createExportTemplateTestRender,
};
