import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `POST /API/collection/project/`
 *
 * @function project.createProject
 * @param {Object} obj
 * @param {Object} obj.projectDocument
 * @returns {Promise<Object>}
 */
function createProject({ projectDocument = isRequired(), ...props }) {
  const path = '/API/collection/project/';
  return vFetch({
    path,
    method: 'POST',
    body: projectDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `POST /API/collection/${collectionId}/version`
 *
 * @function project.createProjectVersion
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {Object} obj.projectVersionDocument
 * @returns {Promise<Object>}
 */
function createProjectVersion({
  collectionId = isRequired(),
  projectVersionDocument = isRequired(),
  ...props
}) {
  const path = `/API/collection/${collectionId}/version`;
  return vFetch({
    path,
    method: 'POST',
    body: projectVersionDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/definition`
 *
 * @function project.listProjectDefinition
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @returns {Promise<Object>}
 */
function listProjectDefinition({
  collectionId = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition`;
  const headers = { accept: 'text/plain', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/definition/${format}`
 *
 * @function project.updateProjectDefinition
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @param {string} obj.body
 * @returns {Promise<Object>}
 */
function updateProjectDefinition({
  collectionId = isRequired(),
  format = isRequired(),
  body = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}`;
  const headers = { contentType: 'application/octet-stream', ...propsHeaders };
  return vFetch({
    path,
    method: 'PUT',
    body,
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/definition/${format}`
 *
 * @function project.getProjectDefinition
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function getProjectDefinition({
  collectionId = isRequired(),
  format = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}`;
  const headers = { accept: 'application/octet-stream', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `DELETE /API/collection/${collectionId}/definition/${format}`
 *
 * @function project.removeProjectDefinition
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function removeProjectDefinition({ collectionId = isRequired(), format = isRequired(), ...props }) {
  const path = `/API/collection/${collectionId}/definition/${format}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/definition/${format}/asset`
 *
 * @function project.getProjectDefinitionAsset
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function getProjectDefinitionAsset({
  collectionId = isRequired(),
  format = isRequired(),
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}/asset`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/definition/${format}/asset`
 *
 * @function project.updateProjectDefinitionAsset
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @param {Object} obj.essenceMappingsDocument
 * @returns {Promise<Object>}
 */
function updateProjectDefinitionAsset({
  collectionId = isRequired(),
  format = isRequired(),
  essenceMappingsDocument = isRequired(),
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}/asset`;
  return vFetch({
    path,
    method: 'PUT',
    body: essenceMappingsDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `DELETE /API/collection/${collectionId}/definition/${format}/asset`
 *
 * @function project.removeProjectDefinitionAsset
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function removeProjectDefinitionAsset({
  collectionId = isRequired(),
  format = isRequired(),
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}/asset`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `GET /API/collection/${collectionId}/definition/${format}/extradata`
 *
 * @function project.getProjectDefinitionExtraData
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function getProjectDefinitionExtraData({
  collectionId = isRequired(),
  format = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}/extradata`;
  const headers = { accept: 'application/octet-stream', ...propsHeaders };
  return vFetch({
    path,
    method: 'GET',
    headers,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `PUT /API/collection/${collectionId}/definition/${format}/extradata`
 *
 * @function project.updateProjectDefinitionExtraData
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @param {Object} obj.body
 * @returns {Promise<Object>}
 */
function updateProjectDefinitionExtraData({
  collectionId = isRequired(),
  format = isRequired(),
  body = isRequired(),
  headers: propsHeaders = {},
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}/extradata`;
  const headers = { contentType: 'application/octet-stream', ...propsHeaders };
  return vFetch({
    path,
    method: 'PUT',
    headers,
    body,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `DELETE /API/collection/${collectionId}/definition/${format}/extradata`
 *
 * @function project.removeProjectDefinitionExtraData
 * @param {Object} obj
 * @param {string} obj.collectionId
 * @param {string} obj.format
 * @returns {Promise<Object>}
 */
function removeProjectDefinitionExtraData({
  collectionId = isRequired(),
  format = isRequired(),
  ...props
}) {
  const path = `/API/collection/${collectionId}/definition/${format}/extradata`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `POST /API/collection/project/inspect`
 *
 * @function project.inspectProjectFile
 * @param {Object} obj
 * @param {Object} obj.essenceMappingsDocument
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function inspectProjectFile({ queryParams = isRequired(), essenceMappingsDocument, ...props }) {
  const path = '/API/collection/project/inspect';
  return vFetch({
    path,
    method: 'POST',
    body: essenceMappingsDocument,
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `POST /API/import/project`
 *
 * @function project.importProject
 * @param {Object} obj
 * @param {Object} obj.essenceMappingsDocument
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function importProject({ queryParams = isRequired(), essenceMappingsDocument, ...props }) {
  const path = '/API/import/project';
  return vFetch({
    path,
    method: 'POST',
    body: essenceMappingsDocument,
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `POST /API/import/project/sequence`
 *
 * @function project.importProjectSequence
 * @param {Object} obj
 * @param {Object} obj.essenceMappingsDocument
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function importProjectSequence({ queryParams = isRequired(), essenceMappingsDocument, ...props }) {
  const path = '/API/import/project/sequence';
  return vFetch({
    path,
    method: 'POST',
    body: essenceMappingsDocument,
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/project.html APIdoc}
 *
 * `POST /API/collection/${collectionId}/version/export`
 *
 * @function project.exportProjectVersionSequence
 * @param {Object} obj
 * @param {sttring} obj.collectionId
 * @param {Object} obj.exportRequestDocument
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function exportProjectVersionSequence({
  collectionId = isRequired(),
  queryParams = isRequired(),
  exportRequestDocument,
  ...props
}) {
  const path = `/API/collection/${collectionId}/version/export`;
  let method = 'GET';
  if (exportRequestDocument) {
    method = 'POST';
  }
  return vFetch({
    path,
    method,
    body: exportRequestDocument,
    queryParams,
    ...props,
  });
}

export default {
  createProject,
  createProjectVersion,
  listProjectDefinition,
  updateProjectDefinition,
  getProjectDefinition,
  removeProjectDefinition,
  getProjectDefinitionAsset,
  updateProjectDefinitionAsset,
  removeProjectDefinitionAsset,
  getProjectDefinitionExtraData,
  updateProjectDefinitionExtraData,
  removeProjectDefinitionExtraData,
  inspectProjectFile,
  importProject,
  importProjectSequence,
  exportProjectVersionSequence,
};
