import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/document-metadata.html APIdoc}
 *
 * `GET /API/document/`
 *
 * @function documentmetadata.listDocumentMetadata
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listDocumentMetadata({ ...props } = {}) {
  const path = '/API/document/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/document-metadata.html#search-for-documents APIdoc}
 *
 * `PUT /API/document/`
 *
 * @function documentmetadata.searchDocumentMetadata
 * @param {Object} obj
 * @param {Object} obj.itemSearchDocument
 * @returns {Promise<Object>}
 */
function searchDocumentMetadata({ itemSearchDocument, ...props } = {}) {
  const path = '/API/document/';
  return vFetch({
    path,
    method: 'PUT',
    body: itemSearchDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/document-metadata.html APIdoc}
 *
 * `GET /API/document/${documentMetadataName}`
 *
 * @function documentmetadata.getDocumentMetadata
 * @param {Object} obj
 * @param {string} obj.documentMetadataName
 * @returns {Promise<Object>}
 */
function getDocumentMetadata({ documentMetadataName = isRequired(), ...props }) {
  const path = `/API/document/${documentMetadataName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/document-metadata.html APIdoc}
 *
 * `PUT /API/document/${documentMetadataName}`
 *
 * @function documentmetadata.updateDocumentMetadata
 * @param {Object} obj
 * @param {string} obj.documentMetadataName
 * @param {Object} obj.metadataDocument
 * @returns {Promise<Object>}
 */
function updateDocumentMetadata({
  documentMetadataName = isRequired(),
  metadataDocument,
  ...props
}) {
  const path = `/API/document/${documentMetadataName}`;
  return vFetch({
    path,
    body: metadataDocument,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/document-metadata.html APIdoc}
 *
 * `GET /API/document/${documentMetadataName}/changes`
 *
 * @function documentmetadata.getDocumentMetadataChanges
 * @param {Object} obj
 * @param {string} obj.documentMetadataName
 * @returns {Promise<Object>}
 */
function getDocumentMetadataChanges({ documentMetadataName = isRequired(), ...props }) {
  const path = `/API/document/${documentMetadataName}/changes`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/document-metadata.html APIdoc}
 *
 * `DELETE /API/document/${documentMetadataName}`
 *
 * @function documentmetadata.removeDocumentMetadata
 * @param {Object} obj
 * @param {string} obj.documentMetadataName
 * @returns {Promise<Object>}
 */
function removeDocumentMetadata({ documentMetadataName = isRequired(), ...props }) {
  const path = `/API/document/${documentMetadataName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listDocumentMetadata,
  searchDocumentMetadata,
  getDocumentMetadata,
  updateDocumentMetadata,
  getDocumentMetadataChanges,
  removeDocumentMetadata,
};
