import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-group.html APIdoc}
 *
 * `GET /API/task-group`
 *
 * @function taskgroup.listTaskGroup
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listTaskGroup({ ...props } = {}) {
  const path = '/API/task-group';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-group.html APIdoc}
 *
 * `PUT /API/task-group/${groupName}`
 *
 * @function taskgroup.updateTaskGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {Object} obj.taskGroupDocument
 * @returns {Promise<Object>}
 */
function updateTaskGroup({ groupName = isRequired(), taskGroupDocument, ...props }) {
  const path = `/API/task-group/${groupName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: taskGroupDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-group.html APIdoc}
 *
 * `GET /API/task-group/${groupName}`
 *
 * @function taskgroup.getTaskGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getTaskGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/task-group/${groupName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-group.html APIdoc}
 *
 * `DELETE /API/task-group/${groupName}`
 *
 * @function taskgroup.removeTaskGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function removeTaskGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/task-group/${groupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-group.html APIdoc}
 *
 * `DELETE /API/task-group`
 *
 * @function taskgroup.removeAllTaskGroup
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeAllTaskGroup({ ...props } = {}) {
  const path = '/API/task-group';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-group.html APIdoc}
 *
 * `PUT /API/task-group/${groupName}/transcoder/${transcoderId}`
 *
 * @function taskgroup.updateTaskGroupTranscoder
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.transcoderId
 * @param {Object} obj.taskGroupDocument
 * @returns {Promise<Object>}
 */
function updateTaskGroupTranscoder({
  groupName = isRequired(),
  transcoderId = isRequired(),
  taskGroupDocument,
  ...props
}) {
  const path = `/API/task-group/${groupName}/transcoder/${transcoderId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/task-group.html APIdoc}
 *
 * `DELETE /API/task-group/${groupName}/transcoder/${transcoderId}`
 *
 * @function taskgroup.removeTaskGroupTranscoder
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.transcoderId
 * @returns {Promise<Object>}
 */
function removeTaskGroupTranscoder({
  groupName = isRequired(),
  transcoderId = isRequired(),
  ...props
}) {
  const path = `/API/task-group/${groupName}/transcoder/${transcoderId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listTaskGroup,
  updateTaskGroup,
  getTaskGroup,
  removeTaskGroup,
  removeAllTaskGroup,
  updateTaskGroupTranscoder,
  removeTaskGroupTranscoder,
};
