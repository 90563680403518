import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/`
 *
 * @function configuration.getConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getConfiguration({ ...props } = {}) {
  const path = '/API/configuration/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/properties`
 *
 * @function configuration.getPropertiesConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getPropertiesConfiguration({ ...props } = {}) {
  const path = '/API/configuration/properties';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/properties`
 *
 * @function configuration.updatePropertiesConfiguration
 * @param {Object} obj
 * @param {Object} obj.configurationPropertyDocument
 * @returns {Promise<Object>}
 */
function updatePropertiesConfiguration({ configurationPropertyDocument, ...props }) {
  const path = '/API/configuration/properties/';
  return vFetch({
    path,
    method: 'PUT',
    body: configurationPropertyDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `POST /API/configuration/properties`
 *
 * @function configuration.updateAllPropertiesConfiguration
 * @param {Object} obj
 * @param {Object} obj.configurationPropertyListDocument
 * @returns {Promise<Object>}
 */
function updateAllPropertiesConfiguration({
  configurationPropertyListDocument = isRequired(),
  ...props
}) {
  const path = '/API/configuration/properties/';
  return vFetch({
    path,
    method: 'POST',
    body: configurationPropertyListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `DELETE /API/configuration/properties/${key}`
 *
 * @function configuration.removePropertiesConfiguration
 * @param {Object} obj
 * @param {string} obj.key
 * @returns {Promise<Object>}
 */
function removePropertiesConfiguration({ key = isRequired(), ...props }) {
  const path = `/API/configuration/properties/${key}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/indexing`
 *
 * @function configuration.getIndexingConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getIndexingConfiguration({ ...props } = {}) {
  const path = '/API/configuration/indexing';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/indexing`
 *
 * @function configuration.updateIndexingConfiguration
 * @param {Object} obj
 * @param {Object} obj.indexingConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateIndexingConfiguration({ indexingConfigurationDocument = isRequired(), ...props }) {
  const path = '/API/configuration/indexing';
  return vFetch({
    path,
    method: 'PUT',
    body: indexingConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/ftp-pool`
 *
 * @function configuration.getFtpPoolConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getFtpPoolConfiguration({ ...props } = {}) {
  const path = '/API/configuration/ftp-pool';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/ftp-pool`
 *
 * @function configuration.updateFtpPoolConfiguration
 * @param {Object} obj
 * @param {Object} obj.ftpPoolConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateFtpPoolConfiguration({ ftpPoolConfigurationDocument = isRequired(), ...props }) {
  const path = '/API/configuration/ftp-pool';
  return vFetch({
    path,
    method: 'PUT',
    body: ftpPoolConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `DELETE /API/configuration/ftp-pool`
 *
 * @function configuration.removeFtpPoolConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeFtpPoolConfiguration({ ...props }) {
  const path = '/API/configuration/ftp-pool';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/job-pool`
 *
 * @function configuration.getJobPoolConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getJobPoolConfiguration({ ...props } = {}) {
  const path = '/API/configuration/job-pool';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/job-pool`
 *
 * @function configuration.updateJobPoolConfiguration
 * @param {Object} obj
 * @param {Object} obj.jobPoolListDocument
 * @returns {Promise<Object>}
 */
function updateJobPoolConfiguration({ jobPoolListDocument = isRequired(), ...props }) {
  const path = '/API/configuration/job-pool';
  return vFetch({
    path,
    method: 'PUT',
    body: jobPoolListDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `DELETE /API/configuration/job-pool`
 *
 * @function configuration.removeAllJobPoolConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeAllJobPoolConfiguration({ ...props }) {
  const path = '/API/configuration/job-pool';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `DELETE /API/configuration/job-pool/${jobPoolPriority}`
 *
 * @function configuration.removeJobPoolConfiguration
 * @param {Object} obj
 * @param {string} obj.jobPoolPriority
 * @returns {Promise<Object>}
 */
function removeJobPoolConfiguration({ jobPoolPriority = isRequired(), ...props }) {
  const path = `/API/configuration/job-pool/${jobPoolPriority}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/metrics`
 *
 * @function configuration.getMetricsConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getMetricsConfiguration({ ...props } = {}) {
  const path = '/API/configuration/metrics';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/metrics`
 *
 * @function configuration.updateMetricsConfiguration
 * @param {Object} obj
 * @param {Object} obj.metricsConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateMetricsConfiguration({ metricsConfigurationDocument = isRequired(), ...props }) {
  const path = '/API/configuration/metrics';
  return vFetch({
    path,
    method: 'PUT',
    body: metricsConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/path-alias`
 *
 * @function configuration.getPathAliasConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getPathAliasConfiguration({ ...props } = {}) {
  const path = '/API/configuration/path-alias';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/path-alias`
 *
 * @function configuration.updatePathAliasConfiguration
 * @param {Object} obj
 * @param {Object} obj.pathAliasConfigurationDocument
 * @returns {Promise<Object>}
 */
function updatePathAliasConfiguration({ pathAliasConfigurationDocument = isRequired(), ...props }) {
  const path = '/API/configuration/path-alias';
  return vFetch({
    path,
    method: 'PUT',
    body: pathAliasConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/logreport`
 *
 * @function configuration.getLogReportConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getLogReportConfiguration({ ...props } = {}) {
  const path = '/API/configuration/logreport';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/logreport`
 *
 * @function configuration.updateLogReportConfiguration
 * @param {Object} obj
 * @param {Object} obj.logReportConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateLogReportConfiguration({ logReportConfigurationDocument = isRequired(), ...props }) {
  const path = '/API/configuration/logreport';
  return vFetch({
    path,
    method: 'PUT',
    body: logReportConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/purging`
 *
 * @function configuration.getDatabasePurgingConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getDatabasePurgingConfiguration({ ...props } = {}) {
  const path = '/API/configuration/purging';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/purging`
 *
 * @function configuration.updateDatabasePurgingConfiguration
 * @param {Object} obj
 * @param {Object} obj.databasePurgingConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateDatabasePurgingConfiguration({
  databasePurgingConfigurationDocument = isRequired(),
  ...props
}) {
  const path = '/API/configuration/purging';
  return vFetch({
    path,
    method: 'PUT',
    body: databasePurgingConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `DELETE /API/configuration/purging`
 *
 * @function configuration.removeDatabasePurgingConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeDatabasePurgingConfiguration({ ...props }) {
  const path = '/API/configuration/purging';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/cors`
 *
 * @function configuration.getCorsConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getCorsConfiguration({ ...props } = {}) {
  const path = '/API/configuration/cors';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/cors`
 *
 * @function configuration.updateCorsConfiguration
 * @param {Object} obj
 * @param {Object} obj.corsConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateCorsConfiguration({ corsConfigurationDocument = isRequired(), ...props }) {
  const path = '/API/configuration/cors';
  return vFetch({
    path,
    method: 'PUT',
    body: corsConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `GET /API/configuration/auth`
 *
 * @function configuration.getAuthConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getAuthConfiguration({ ...props } = {}) {
  const path = '/API/configuration/auth';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `PUT /API/configuration/auth`
 *
 * @function configuration.updateAuthConfiguration
 * @param {Object} obj
 * @param {Object} obj.oAuth2ConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateAuthConfiguration({ oAuth2ConfigurationDocument = isRequired(), ...props }) {
  const path = '/API/configuration/auth';
  return vFetch({
    path,
    method: 'PUT',
    body: oAuth2ConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html APIdoc}
 *
 * `DELETE /API/configuration/auth`
 *
 * @function configuration.removeAuthConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeAuthConfiguration({ ...props } = {}) {
  const path = '/API/configuration/auth';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html#retrieve-the-default-job-priority-configuration APIdoc}
 *
 * `GET /API/configuration/job-priority`
 *
 * @function configuration.getJobPriorityConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getJobPriorityConfiguration({ ...props } = {}) {
  const path = '/API/configuration/job-priority';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link hhttps://apidoc.vidispine.com/latest/ref/property.html#update-the-default-job-priority-configuration APIdoc}
 *
 * `PUT /API/configuration/job-priority`
 *
 * @function configuration.updateJobPriorityConfiguration
 * @param {Object} obj
 * @param {Object} obj.jobPriorityConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateJobPriorityConfiguration({
  jobPriorityConfigurationDocument = isRequired(),
  ...props
}) {
  const path = '/API/configuration/job-priority';
  return vFetch({
    path,
    method: 'PUT',
    body: jobPriorityConfigurationDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html#remove-the-default-job-priority-configuration APIdoc}
 *
 * `DELETE /API/configuration/job-priority`
 *
 * @function configuration.removeJobPriorityConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeJobPriorityConfiguration({ ...props } = {}) {
  const path = '/API/configuration/job-priority';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html#bulky-metadata-storage-configuration APIdoc}
 *
 * `GET /API/configuration/bulkymetadata`
 *
 * @function configuration.getBulkyMetadataConfiguration
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getBulkyMetadataConfiguration({ ...props } = {}) {
  const path = '/API/configuration/bulkymetadata';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/property.html#update-the-bulky-metdata-storage-configuration APIdoc}
 *
 * `PUT /API/configuration/bulkymetadata`
 *
 * @function configuration.updateBulkyMetadataConfiguration
 * @param {Object} obj
 * @param {Object} obj.bulkyMetadataConfigurationDocument
 * @returns {Promise<Object>}
 */
function updateBulkyMetadataConfiguration({
  bulkyMetadataConfigurationDocument = isRequired(),
  ...props
}) {
  const path = '/API/configuration/bulkymetadata';
  return vFetch({
    path,
    method: 'PUT',
    body: bulkyMetadataConfigurationDocument,
    ...props,
  });
}

export default {
  getConfiguration,
  getPropertiesConfiguration,
  updatePropertiesConfiguration,
  updateAllPropertiesConfiguration,
  removePropertiesConfiguration,
  getIndexingConfiguration,
  updateIndexingConfiguration,
  getFtpPoolConfiguration,
  updateFtpPoolConfiguration,
  removeFtpPoolConfiguration,
  getJobPoolConfiguration,
  updateJobPoolConfiguration,
  removeAllJobPoolConfiguration,
  removeJobPoolConfiguration,
  getMetricsConfiguration,
  updateMetricsConfiguration,
  getPathAliasConfiguration,
  updatePathAliasConfiguration,
  getLogReportConfiguration,
  updateLogReportConfiguration,
  getDatabasePurgingConfiguration,
  updateDatabasePurgingConfiguration,
  removeDatabasePurgingConfiguration,
  getCorsConfiguration,
  updateCorsConfiguration,
  getAuthConfiguration,
  updateAuthConfiguration,
  removeAuthConfiguration,
  getJobPriorityConfiguration,
  updateJobPriorityConfiguration,
  removeJobPriorityConfiguration,
  getBulkyMetadataConfiguration,
  updateBulkyMetadataConfiguration,
};
