import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/schema.html APIdoc}
 *
 * `GET /API/metadata-schema`
 *
 * @function metadataschema.getMetadataSchema
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getMetadataSchema({ ...props } = {}) {
  const path = '/API/metadata-schema';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/schema.html APIdoc}
 *
 * `PUT /API/metadata-schema`
 *
 * @function metadataschema.updateMetadataSchema
 * @param {Object} obj
 * @param {Object} obj.metadataSchemaDocument
 * @returns {Promise<Object>}
 */
function updateMetadataSchema({ metadataSchemaDocument = isRequired(), ...props }) {
  const path = '/API/metadata-schema';
  return vFetch({
    path,
    method: 'PUT',
    body: metadataSchemaDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/schema.html APIdoc}
 *
 * `DELETE /API/metadata-schema`
 *
 * @function metadataschema.removeMetadataSchema
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function removeMetadataSchema({ ...props } = {}) {
  const path = '/API/metadata-schema';
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/schema.html APIdoc}
 *
 * `GET /API/metadata-schema${groupName}`
 *
 * @function metadataschema.getMetadataSchemaGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getMetadataSchemaGroup({ groupName = isRequired(), ...props } = {}) {
  const path = `/API/metadata-schema${groupName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/schema.html APIdoc}
 *
 * `PUT /API/metadata-schema${groupName}`
 *
 * @function metadataschema.updateMetadataSchemaGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {Object} obj.metadataSchemaGroupDocument
 * @returns {Promise<Object>}
 */
function updateMetadataSchemaGroup({
  groupName = isRequired(),
  metadataSchemaGroupDocument = isRequired(),
  ...props
} = {}) {
  const path = `/API/metadata-schema${groupName}`;
  return vFetch({
    path,
    method: 'PUT',
    body: metadataSchemaGroupDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/schema.html APIdoc}
 *
 * `DELETE /API/metadata-schema${groupName}`
 *
 * @function metadataschema.removeMetadataSchemaGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function removeMetadataSchemaGroup({ groupName = isRequired(), ...props } = {}) {
  const path = `/API/metadata-schema${groupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/metadata/schema.html APIdoc}
 *
 * `GET /API/metadata-field/terse-schema`
 *
 * @function metadataschema.getTerseMetadataSchema
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function getTerseMetadataSchema({ ...props } = {}) {
  const path = '/API/metadata-field/terse-schema';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  getMetadataSchema,
  updateMetadataSchema,
  removeMetadataSchema,
  getMetadataSchemaGroup,
  updateMetadataSchemaGroup,
  removeMetadataSchemaGroup,
  getTerseMetadataSchema,
};
