import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/quota.html APIdoc}
 *
 * `GET /API/quota/`
 *
 * @function quota.listQuota
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listQuota({ ...props } = {}) {
  const path = '/API/quota/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/quota.html APIdoc}
 *
 * `POST /API/quota/`
 *
 * @function quota.createQuota
 * @param {Object} obj
 * @param {Object} obj.quotaRuleDocument
 * @returns {Promise<Object>}
 */
function createQuota({ quotaRuleDocument, ...props }) {
  const path = '/API/quota/';
  return vFetch({
    path,
    method: 'POST',
    body: quotaRuleDocument,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/quota.html APIdoc}
 *
 * `GET /API/quota/${ruleId}`
 *
 * @function quota.getQuota
 * @param {Object} obj
 * @param {string} obj.ruleId
 * @returns {Promise<Object>}
 */
function getQuota({ ruleId = isRequired(), ...props }) {
  const path = `/API/quota/${ruleId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/quota.html APIdoc}
 *
 * `PUT /API/quota/${ruleId}`
 *
 * @function quota.evaluateQuota
 * @param {Object} obj
 * @param {string} obj.ruleId
 * @returns {Promise<Object>}
 */
function evaluateQuota({ ruleId = isRequired(), ...props }) {
  const path = `/API/quota/${ruleId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/quota.html APIdoc}
 *
 * `DELETE /API/quota/${ruleId}`
 *
 * @function quota.removeQuota
 * @param {Object} obj
 * @param {string} obj.ruleId
 * @returns {Promise<Object>}
 */
function removeQuota({ ruleId = isRequired(), ...props }) {
  const path = `/API/quota/${ruleId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listQuota,
  createQuota,
  getQuota,
  evaluateQuota,
  removeQuota,
};
