import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/transfer.html APIdoc}
 *
 * `GET /API/transfer/`
 *
 * @function transfer.listTransfer
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listTransfer({ ...props } = {}) {
  const path = '/API/transfer/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/transfer.html APIdoc}
 *
 * `GET /API/transfer/${transferId}`
 *
 * @function transfer.getTransfer
 * @param {Object} obj
 * @param {string} obj.transferId
 * @returns {Promise<Object>}
 */
function getTransfer({ transferId = isRequired(), ...props }) {
  const path = `/API/transfer/${transferId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/transfer.html APIdoc}
 *
 * `PUT /API/transfer/${transferId}`
 *
 * @function transfer.updateTransferPriority
 * @param {Object} obj
 * @param {string} obj.transferId
 * @returns {Promise<Object>}
 */
function updateTransferPriority({ transferId = isRequired(), ...props }) {
  const path = `/API/transfer/${transferId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/transfer.html APIdoc}
 *
 * `GET /API/log/transfer-log/`
 *
 * @function transfer.listTransferLog
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listTransferLog({ ...props } = {}) {
  const path = '/API/log/transfer-log/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  listTransfer,
  getTransfer,
  updateTransferPriority,
  listTransferLog,
};
