import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/misc/debug.html?highlight=echo#echo APIdoc}
 *
 * `PUT /APInoauth/debug/echo`
 *
 * @function debug.echo
 * @param {Object} obj
 * @param {Object} obj.xmlDocument
 * @returns {Promise<Object>}
 */
function echo({ xmlDocument = isRequired(), headers: propHeaders = {}, ...props }) {
  return vFetch({
    path: '/APInoauth/debug/echo',
    method: 'PUT',
    body: xmlDocument,
    headers: { contentType: 'application/xml', ...propHeaders },
    ...props,
  });
}

export default { echo };
