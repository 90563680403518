import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-group.html APIdoc}
 *
 * `GET /API/storage/storage-group/`
 *
 * @function storagegroup.listStorageGroup
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listStorageGroup({ ...props } = {}) {
  const path = '/API/storage/storage-group/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-group.html APIdoc}
 *
 * `PUT /API/storage/storage-group/${groupName}`
 *
 * @function storagegroup.createStorageGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function createStorageGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/storage/storage-group/${groupName}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-group.html APIdoc}
 *
 * `GET /API/storage/storage-group/${groupName}`
 *
 * @function storagegroup.getStorageGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function getStorageGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/storage/storage-group/${groupName}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-group.html APIdoc}
 *
 * `DELETE /API/storage/storage-group/${groupName}`
 *
 * @function storagegroup.removeStorageGroup
 * @param {Object} obj
 * @param {string} obj.groupName
 * @returns {Promise<Object>}
 */
function removeStorageGroup({ groupName = isRequired(), ...props }) {
  const path = `/API/storage/storage-group/${groupName}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-group.html APIdoc}
 *
 * `PUT /API/storage/storage-group/${groupName}/${storageId}`
 *
 * @function storagegroup.addStorageGroupStorage
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function addStorageGroupStorage({ groupName = isRequired(), storageId = isRequired(), ...props }) {
  const path = `/API/storage/storage-group/${groupName}/${storageId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/storage/storage-group.html APIdoc}
 *
 * `DELETE /API/storage/storage-group/${groupName}/${storageId}`
 *
 * @function storagegroup.removeStorageGroupStorage
 * @param {Object} obj
 * @param {string} obj.groupName
 * @param {string} obj.storageId
 * @returns {Promise<Object>}
 */
function removeStorageGroupStorage({
  groupName = isRequired(),
  storageId = isRequired(),
  ...props
}) {
  const path = `/API/storage/storage-group/${groupName}/${storageId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

export default {
  listStorageGroup,
  createStorageGroup,
  getStorageGroup,
  removeStorageGroup,
  addStorageGroupStorage,
  removeStorageGroupStorage,
};
