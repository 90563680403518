import { parseShapeType } from './parseShapeType';

const IMAGE_MIME_TYPES = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/x-icon',
  'image/png',
  'image/svg+xml',
];

const VIDEO_MIME_TYPES = ['video/mp4'];

const AUDIO_MIME_TYPES = [
  'audio/mp4',
  'audio/mpeg',
  'audio/x-aac',
  'audio/aac',
  'audio/x-wav',
  'audio/wav',
  'audio/accp',
  'audio/ogg',
  'audio/webm',
  'audio/x-flac',
];

const DEFAULT_ALLOWED_MIME_TYPES = [...VIDEO_MIME_TYPES, ...IMAGE_MIME_TYPES, ...AUDIO_MIME_TYPES];

const DEFAULT_ALLOWED_METHODS = ['http', 'https'];

const MP4_MIMETYPE = 'video/mp4';
const QUICKTIME_MIMETYPE = 'video/quicktime';
const MP4_CONTAINER = 'MPEG-4';
const MOV_CONTAINER = 'mov';
const H264_CODEC = 'h264';

// Sort by tags in the order of previewShapeOrder, secondary alphabetical.
// using reverse() so the first has highest index
const PREVIEW_SHAPE_ORDER = [
  '__mp4',
  '__mp3_160k',
  '__png',
  '__jpeg',
  '__gif',
  'original',
].reverse();

const defaultSortPriority = ({ shape: { tag: firstEl } }, { shape: { tag: secondEl } }) => {
  if (PREVIEW_SHAPE_ORDER.includes(firstEl) || PREVIEW_SHAPE_ORDER.includes(secondEl)) {
    return PREVIEW_SHAPE_ORDER.indexOf(secondEl) - PREVIEW_SHAPE_ORDER.indexOf(firstEl);
  }
  return firstEl.toLowerCase().localeCompare(secondEl.toLowerCase());
};

const filterShapeSource = (itemType = {}, options = {}) => {
  const {
    allowedMimeTypes = DEFAULT_ALLOWED_MIME_TYPES,
    sortPriority = defaultSortPriority,
    allowedMethods = DEFAULT_ALLOWED_METHODS,
    sourceKey = 'src',
    mimeTypeKey = 'type',
    shapeKey = 'shape',
  } = options;
  const { shape: shapeList = [] } = itemType;
  const parsedShapeList = shapeList.reduce((acc, shapeType) => {
    let type;
    let src;
    const shape = parseShapeType(shapeType);
    const { uri, mimeType, containerFormat, videoCodec } = shape;
    if (mimeType) {
      type = mimeType;
      if (
        mimeType === QUICKTIME_MIMETYPE &&
        (containerFormat === MP4_CONTAINER || containerFormat === MOV_CONTAINER) &&
        videoCodec === H264_CODEC
      )
        type = MP4_MIMETYPE;
    }
    if (uri) {
      if (allowedMethods) {
        const [thisMethod] = uri.split('://');
        if (allowedMethods.includes(thisMethod)) src = uri;
      } else {
        src = uri;
      }
    }
    if (src && allowedMimeTypes.includes(type)) {
      acc.push({
        [sourceKey]: src,
        [mimeTypeKey]: type,
        [shapeKey]: shape,
      });
    }
    return acc;
  }, []);
  if (typeof sortPriority === 'function') parsedShapeList.sort(defaultSortPriority);
  return parsedShapeList;
};

export default filterShapeSource;
