const createFacetType = (fieldList = [], options = {}) =>
  fieldList.map((field) => ({
    field,
    name: field,
    count: options.count || true,
    exclude: fieldList,
  }));

// eslint-disable-next-line import/prefer-default-export
export { createFacetType };
