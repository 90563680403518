/* eslint react/prop-types: "off" */
import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { item as ItemApi } from '@vidispine/vdt-api';

import useApi from '../hooks/useApi';

export default function GetItem({ itemId, queryParams, children }) {
  const { request, data: itemType = {}, isLoading, isError } = useApi(ItemApi.getItem);
  const onRefresh = () => request({ itemId, queryParams });
  useDeepCompareEffect(() => {
    onRefresh();
  }, [itemId, queryParams]);
  const cloneProps = {
    itemId,
    queryParams,
    itemType,
    onRefresh,
    isLoading,
    isError,
  };
  const cloneChild = (child) => React.cloneElement(child, cloneProps);
  return React.Children.map(children, cloneChild);
}
