/* eslint max-len: ["error", { "ignoreComments": true }] */
import { vFetch, isRequired } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/component`
 *
 * @function component.listComponent
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line max-len
function listComponent({ itemId = isRequired(), shapeId = isRequired(), ...props }) {
  const path = `/API/item/${itemId}/shape/${shapeId}/component`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `GET /API/item/${itemId}/shape/${shapeId}/component/${componentId}`
 *
 * @function component.getComponent
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @returns {Promise<Object>}
 */
function getComponent({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}`;
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `DELETE /API/item/${itemId}/shape/${shapeId}/component/${componentId}`
 *
 * @function component.removeComponent
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @returns {Promise<Object>}
 */
function removeComponent({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}`;
  return vFetch({
    path,
    method: 'DELETE',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/component`
 *
 * @function component.createComponent
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function createComponent({
  itemId = isRequired(),
  shapeId = isRequired(),
  queryParams = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/component`;
  return vFetch({
    path,
    method: 'POST',
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/component/placeholder`
 *
 * @function component.createComponentPlaceholder
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {Object} obj.queryParams
 * @returns {Promise<Object>}
 */
function createComponentPlaceholder({
  itemId = isRequired(),
  shapeId = isRequired(),
  queryParams = isRequired(),
  ...props
}) {
  const path = `/API/item/${itemId}/shape/${shapeId}/component/placeholder`;
  return vFetch({
    path,
    method: 'POST',
    queryParams,
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/component/${componentId}/move/item/${targetItemId}/shape/${targetShapeId}/component/${targetComponentId}`
 *
 * @function component.moveComponent
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @param {string} obj.targetItemId
 * @param {string} obj.targetShapeId
 * @param {string} obj.targetComponentId
 * @returns {Promise<Object>}
 */
function moveComponent({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  targetItemId = isRequired(),
  targetShapeId = isRequired(),
  targetComponentId = isRequired(),
  ...props
}) {
  // eslint-disable-next-line max-len
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}/move/item/${targetItemId}/shape/${targetShapeId}/component/${targetComponentId}`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/component/${componentId}/move/item/${targetItemId}/shape/${targetShapeId}`
 *
 * @function component.moveComponentToShape
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @param {string} obj.targetItemId
 * @param {string} obj.targetShapeId
 * @returns {Promise<Object>}
 */
function moveComponentToShape({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  targetItemId = isRequired(),
  targetShapeId = isRequired(),
  ...props
}) {
  // eslint-disable-next-line max-len
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}/move/item/${targetItemId}/shape/${targetShapeId}`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/component/${componentId}/copy/item/${targetItemId}/shape/${targetShapeId}/component/${targetComponentId}`
 *
 * @function component.copyComponent
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @param {string} obj.targetItemId
 * @param {string} obj.targetShapeId
 * @param {string} obj.targetComponentId
 * @returns {Promise<Object>}
 */
function copyComponent({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  targetItemId = isRequired(),
  targetShapeId = isRequired(),
  targetComponentId = isRequired(),
  ...props
}) {
  // eslint-disable-next-line max-len
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}/copy/item/${targetItemId}/shape/${targetShapeId}/component/${targetComponentId}`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `POST /API/item/${itemId}/shape/${shapeId}/component/${componentId}/copy/item/${targetItemId}/shape/${targetShapeId}`
 *
 * @function component.copyComponentToShape
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @param {string} obj.targetItemId
 * @param {string} obj.targetShapeId
 * @returns {Promise<Object>}
 */
function copyComponentToShape({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  targetItemId = isRequired(),
  targetShapeId = isRequired(),
  ...props
}) {
  // eslint-disable-next-line max-len
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}/copy/item/${targetItemId}/shape/${targetShapeId}`;
  return vFetch({
    path,
    method: 'POST',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/component/${componentId}/file/${fileId}`
 *
 * @function component.addComponentFile
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function addComponentFile({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  fileId = isRequired(),
  ...props
}) {
  // eslint-disable-next-line max-len
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}/file/${fileId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/item/component.html APIdoc}
 *
 * `PUT /API/item/${itemId}/shape/${shapeId}/component/${componentId}/file/${fileId}`
 *
 * @function component.removeComponentFile
 * @param {Object} obj
 * @param {string} obj.itemId
 * @param {string} obj.shapeId
 * @param {string} obj.componentId
 * @param {string} obj.fileId
 * @returns {Promise<Object>}
 */
function removeComponentFile({
  itemId = isRequired(),
  shapeId = isRequired(),
  componentId = isRequired(),
  fileId = isRequired(),
  ...props
}) {
  // eslint-disable-next-line max-len
  const path = `/API/item/${itemId}/shape/${shapeId}/component/${componentId}/file/${fileId}`;
  return vFetch({
    path,
    method: 'PUT',
    ...props,
  });
}

export default {
  listComponent,
  getComponent,
  removeComponent,
  createComponent,
  createComponentPlaceholder,
  moveComponent,
  moveComponentToShape,
  copyComponent,
  copyComponentToShape,
  addComponentFile,
  removeComponentFile,
};
