import { vFetch } from './utils';

/**
 * {@link https://apidoc.vidispine.com/latest/ref/audit-trail.html#list-all-audit-log-entries APIdoc}
 *
 * `GET /API/log`
 *
 * @function auditlog.listAuditLog
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function listAuditLog({ ...props } = {}) {
  const path = '/API/log/';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

/**
 * {@link https://apidoc.vidispine.com/latest/ref/audit-trail.html#retrieve-the-entire-audit-log APIdoc}
 *
 * `GET /API/log/export`
 *
 * @function auditlog.listAuditLog
 * @param {Object} obj
 * @returns {Promise<Object>}
 */
function exportAuditLog({ ...props } = {}) {
  const path = '/API/log/export';
  return vFetch({
    path,
    method: 'GET',
    ...props,
  });
}

export default {
  listAuditLog,
  exportAuditLog,
};
